import { serverApi } from 'classes/Config';
import auth, { token } from './auth';
import { querify } from './url';

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export async function safeResponseJson(response) {
  try {
    const json = await response.json();
    return json;
  } catch {
    return {};
  }
}

export function fetcher(endpoint, options) {
  const defaultOptions = {
    csrfToken: false,
    token: false,
    body: false,
    key: false,
    baseurl: '',
    req: {},
    bearer: undefined,
  };

  const { key, baseurl, csrfToken, token, body, req, bearer } = {
    ...defaultOptions,
    ...options,
  };

  const s = endpoint.indexOf('?') > -1 ? '&' : '?';
  const base = baseurl === '/' ? process.env.NEXT_PUBLIC_APPURL : baseurl;
  const url = `${base}${endpoint}${token ? `${s}token=${token}` : ''}`;

  async function sendRequest(method) {
    const buildOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    // only on server
    if (typeof window === 'undefined' && req.headers) {
      // set ua
      buildOptions.headers['X-KREDIBEL-UA'] = req.headers['user-agent'];

      // set ip
      buildOptions.headers['X-KREDIBEL-IP'] =
        req.headers['cf-connecting-ip'] ||
        req.headers['x-forwarded-for'] ||
        req?.connection?.remoteAddress;
    }

    if (bearer) {
      buildOptions.headers['Authorization'] = `Bearer ${bearer}`;
    }

    if (body && key) body.key = key;
    if (body) buildOptions.body = JSON.stringify(body);

    if (csrfToken) {
      buildOptions.headers['csrf-token'] = csrfToken;
    }

    const response = await fetch(url, {
      method,
      ...buildOptions,
    });

    if (!response.ok) {
      // console.log(response);
    }

    const result = await safeResponseJson(response);

    if (!response.ok && response.status === 403 && process.browser) {
      if (result.code === 'EBADCSRFTOKEN') {
        alert('Page expired. Please reload the page.');
      }
    }

    return { response, result };
  }

  return {
    post: () => sendRequest('POST'),
    put: () => sendRequest('PUT'),
    patch: () => sendRequest('PATCH'),
    get: () => sendRequest('GET'),
    delete: () => sendRequest('DELETE'),
  };
}

export async function fetchWithAuth(endpoint, ctx, res = false) {
  const key = token(ctx);

  const { response, result } = await fetcher(
    querify(endpoint, {
      key,
    }),
    { req: ctx.req },
  ).get();

  if (res) {
    return { response, result };
  }

  return result;
}
